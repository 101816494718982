const initialState = () => ({
  globalMessage: {
    message: null,
    type: null
  },
})

const state = initialState()

const actions = {}

const getters = {
  globalMessage(state){
    return state.globalMessage;
  },
}

const mutations = {
  globalMessage(state,message){
    state.globalMessage = message;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
