const privateRoutes = [
  {
    path: '',
    redirect: '/user-list',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/user-list',
    component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Users/List'),
    name: 'UserList',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/transaction',
    component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Users/Transaction'),
    name: 'Transaction',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/edit-user/:id',
    component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Users/EditUser'),
    name: 'EditUser',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/faqs',
    component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/FAQ/List'),
    name: 'FaqList',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/faqs-ordering',
    component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/FAQ/Ordering'),
    name: 'FaqOrdering',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clothe-type-list',
    component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/ClotheType/List'),
    name: 'ClothTypeList',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clothe-theme-list',
    component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/ClotheTheme/List'),
    name: 'ClothThemeList',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clothe-collection',
    component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Collection/List'),
    name: 'ClothCollection',
    meta: {
      requiresAuth: true,
    },
  },
]
export default privateRoutes
