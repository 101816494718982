import { createStore } from 'vuex'

import Storage from '@/helper/Storage'
import modules from './modules'

export default new createStore({
  modules,
  actions: {
    reset ({ commit }) {
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`)
      })
      Storage.Clear()
    }
  }
})
