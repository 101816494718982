<template>
    <header class="border-bottom pb-3">
        <nav class="navbar navbar-expand-lg navbar-light px-0 bg-light  static-top">


            <router-link :to="{ name: 'UserList' }" class="navbar-brand">
                <h1 class="text-dark font-weight-bold display-5">RapLand</h1>
            </router-link>
            <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button> -->
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="nav navbar-nav ml-auto ">
                    <li class="nav-item active m-1">
                        <router-link :to="{name:'UserList'}"
                            class="form-inline rapland text-green my-auto  text-decoration-none nav-link active">
                            <span @click="list" :class="{'menu-active':showList }">
                                User
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item active m-1">
                        <router-link :to="{name:'Transaction'}"
                            class="form-inline rapland  my-auto  text-decoration-none nav-link active">
                            <span @click="transaction" :class="{'menu-active':showTransaction }">
                                Transaction
                            </span>
                        </router-link>
                    </li>

                    <li class="nav-item active">
                        <form class="form-inline nav-link active">
                            <a href="javascript::void();"
                                class="btn btn-warning rapland text-white my-auto h6 text-decoration-none"
                                @click="logout">Log Out</a>
                        </form>

                    </li>
                </ul>
            </div>
            <!-- <router-link :to="{ name: 'UserList' }" class="text-decoration-none">
                <h1 class="text-dark font-weight-bold display-5">RapLand</h1>
                <img src="@/assets/images/logo.png" alt="logo" class="topbar-logo">
            </router-link>

            <router-link :to="{name:'Transaction'}"
                class="btn btn-warning form-inline rapland text-white my-auto h6 text-decoration-none"
                v-if="this.$route.name == 'UserList'">
                <a @click="transaction" v-show="showTransaction">Transaction history</a>
            </router-link>

            <router-link :to="{name:'UserList'}" class=" text-black my-auto h6 "
                v-if="this.$route.name == 'Transaction'">
                <a v-show="showList" @click="list">User list</a>
            </router-link>
            <form class="form-inline">
                <a href="javascript::void();" class="btn btn-warning rapland text-white my-auto h6 text-decoration-none"
                    @click="logout">Log Out</a>
            </form> -->
        </nav>
    </header>

</template>

<script>

import API from "../../../api/http";

export default {
    name: 'Header',
    data() {
        return {
            publicURL: process.env.VUE_APP_BASE_URL,
            activeList: false,
            showList: true,
            showTransaction: true,
        }
    },
    methods: {
        logout() {
            API.logout().then(() => {
                this.$router.push({ name: 'Login' });
            });
        },
        transaction() {
            this.showList = true;
            this.activeList = true;
            this.$router.push({ name: 'Transaction' });
            this.showTransaction = false;
        },
        list() {
            this.activeList = true;
            this.$router.push({ name: 'UserList' });
            this.showList = false;
            this.showTransaction = true;

        },

    },


}
</script>

