<template>
    <div id="app" :class="{'mx-5 p-0': true, 'admin-login': showLoginElements}">
        <img src="./assets/images/default.png" id="defaultImage" class="hidden" hidden />
        <!-- <Loading /> -->
        <Header v-if="showLoginElements" />

        <template name="fade" v-if="showLoginElements">
            <div class="row mt-3">
                <!-- <div class="col-12 col-lg-2">
                    <SideMenu v-if="showLoginElements" />
                </div> -->
                <div class="col-12 col-lg-12 border-left border-lg-0 border-right">
                    <router-view />
                </div>
            </div>
        </template>
        <template name="fade" mode="out-in" v-else>
            <router-view />
        </template>
    
    </div>
</template>

<script>
    // import SideMenu from './components/Private/Shared/SideMenu';
    import Header from './components/Private/Shared/Header';
    export default {
        name: 'App',
        components: { 
            // SideMenu, 
            Header 
        },
        computed: {
            user() { return this.$store.getters['User/user']  },
            showLoginElements(){
                return this.user && this.user.type == 'admin'
            },
            currentRouteName() {
                return this.$route;
            }
        }
    }
</script>