import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'
import store from '../store'
import { useToast } from "vue-toastification";
const toast = useToast();

const API = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 2000
})

const authInterceptor = (config) => {
  config.headers['Accept'] = 'application/json'
  if(store.getters['User/user'].token){
    config.headers['Authorization'] = 'Bearer ' + store.getters['User/user'].token
  }
  return config
}
API.interceptors.request.use(authInterceptor)

API.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // console.log(error)
  // console.log("=======")
  if (401 === error.response.status) {
    store.dispatch('reset')
    // window.location = process.env.VUE_APP_BASE_URL;
  } else if (500 === error.response.status) {
    // console.log('message')
    var message = '';
    // console.log(error.response.data.errors)
    if(error.response.data.errors){
      if(error.response.data.errors[0]){
        message = error.response.data.errors[0]
      }else{
        // console.log(Object.keys(error.response.data.errors).length)
        Object.values(error.response.data.errors).forEach(val => 
          message = message + val[0] + (Object.keys(error.response.data.errors).length > 1 ? ',' : '')
        );
      }
    }else if (error.response.data.message){
      message = error.response.data.message
      console.log('message3' + message)
    }
    toast.error(message);
    return Promise.reject(error);
  }else {
    return Promise.reject(error);
  }
});

loadProgressBar('', API)

export default API
