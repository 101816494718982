import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserCheck, faUserTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    faTrashAlt, faEdit, 
    faUserCheck, faUserTimes,faTimesCircle
)

export {
    FontAwesomeIcon
}