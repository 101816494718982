const publicRoutes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "publicRoutes" */ '../components/Public/Login'),
    meta: {
      onlyGuest: true
    }
  },
]

export default publicRoutes